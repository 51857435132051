import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import Button from "../components/button";
import buttonIcon from "../images/icons/external-icon.svg";
import Banner from "../components/banner";
import bannerImg from "../images/home/hero-banner.jpg";
import MoveInfo from "../components/moveInfo";
import Cardvideo from "../components/cards/cardVideo";
import CardDownload from "../components/cards/cardDownload";
import TwoCol from "../components/twoCol/twoCol";
import img1 from "../images/home/instructor.jpg";
import ExternalLink from "../components/modals/externalLink";
import StickyButton from "../components/stickyButton";


const Allcomponents = () => {
  return (
    <HcpLayout pageClass="hcp-homepage">
      <div className="centered-content">
        <h2 className="mb-20">Typography</h2>
        <hr />
        <hr />
        <div className="mb-20">
          <h1>Heading1</h1>
          <h2>Heading2</h2>
          <h3>Heading3</h3>
          <h4>Heading4</h4>
          <hr />
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta maiores error optio provident hic, corrupti blanditiis, ducimus vitae eaque voluptatem officiis dolor tenetur distinctio voluptates molestiae inventore quo natus quos laboriosam deleniti. Quia assumenda aut quae dolore. Mollitia a rem quis unde similique amet quae, soluta numquam animi repellat iste. Beatae doloremque dolores nesciunt fugiat, aperiam qui quam? Nisi neque, velit fuga excepturi ut quae repudiandae saepe doloremque voluptates ratione reiciendis quod, blanditiis ab ducimus magnam? Quidem sapiente eligendi ratione nulla, unde expedita, fuga architecto molestiae magni nobis velit quos consequuntur quo perspiciatis praesentium optio consequatur facilis rerum labore voluptatum.</p>
        </div>
        <h2 className="mb-20">Icons</h2>
        <hr />
        <hr />
        <div className="mb-20">

        </div>
        <h2 className="mb-20">Buttons</h2>
        <hr />
        <hr />
        <div className="mb-20">
          <div className="mb-20">
            <span className="d-block mb-10">default button(btn)</span>
            <Button
              btnTitle='Click me'
            />
          </div>
          <div className="mb-20">
            <span className="d-block mb-10">primary button(btn-primary btn-xs)</span>
            <Button
              className='btn-primary btn-xs border-28'
              btnTitle='Download Now'
              btnIcon={buttonIcon}

            />
          </div>
          <div className="mb-20">
            <span className="d-block mb-10">primary button(btn-primary btn-sm)</span>
            <Button
              className='btn-primary btn-sm border-32'
              btnTitle='menu'
            />
          </div>
          <div className="mb-20">
            <span className="d-block mb-10">primary button(btn-primary btn-md)</span>
            <Button
              className='btn-primary btn-md border-32'
              btnTitle='See Treatment Options'
              btnIcon={buttonIcon}
            />
          </div>
          <div className="mb-20">
            <span className="d-block mb-10">primary button(btn-primary btn-lg)</span>
            <Button
              className='btn-primary btn-lg border-36'
              btnTitle='Understand diabetic nerve pain'
            />
          </div>
        </div>
        <h2 className="mb-20">cardvideo</h2>
        <hr />
        <hr />
        <section>
          <Cardvideo />
        </section>
        <h2 className="mb-20">twocol</h2>
        <hr />
        <hr />
        <section>
          <TwoCol
            extraClass='align-center'
            image={img1}
            imageAlt='Instructor'
            heading='Discuss your symptoms with your doctor'
            description={`<p>Think you might have diabetic nerve pain of the feet? Tell your doctor about your painful feet and other symptoms.</p>`}
            btnUrl='/'
            btnclass='btn-primary btn-md border-32'
            btnTitle='Talk with Your Doctor'

          >
            <div className="dot-list">
              <ul>
                <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus, distinctio.</li>
                <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus, distinctio.</li>
                <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus, distinctio.</li>
              </ul>
            </div>
          </TwoCol>
        </section>
        <h2 className="mb-20">twocol reverse</h2>
        <hr />
        <hr />
        <section>
          <TwoCol
            extraClass='align-center reverse'
            image={img1}
            imageAlt='Instructor'
            heading='Discuss your symptoms with your doctor'
            description={`<p>Think you might have diabetic nerve pain of the feet? Tell your doctor about your painful feet and other symptoms.</p>`}
            btnUrl='/'
            btnclass='btn-primary btn-md border-32'
            btnTitle='Talk with Your Doctor'

          >
          </TwoCol>
        </section>
        <h2 className="mb-20">cardDownload</h2>
        <hr />
        <hr />
        <CardDownload
          title='Diabetic Nerve Pain Infographic'
          desc={`<p>See what patients like you are saying about diabetic nerve pain of the feet.</p>`}
          downloadBtnUrl='/'
        />
        <h2 className="mb-20">Banner</h2>
        <hr />
        <hr />
      </div>
      <section className="hero-banner">
        <Banner
          extraClass='gradient-overlay'
          bannerImg={bannerImg}
          bannerImgAlt='Hero image'
          bannerTitle='Don’t let diabetic nerve pain define you.'
          bannerDesc={`<p>If you’re living with diabetes and are experiencing foot pain, you may have diabetic nerve pain, also known as diabetic peripheral neuropathy (DPN).</p>`}
        />
      </section>
      <div className="centered-content">
        <h2 className="mb-20">Banner-inner</h2>
        <hr />
        <hr />
      </div>
      <section className="hero-banner">
        <Banner
          extraClass='gradient-overlay banner-inner'
          bannerImg={bannerImg}
          bannerImgAlt='Hero image'
          bannerTitle='Don’t let diabetic nerve pain define you.'

        />
      </section>
      <div className="centered-content">
        <h2 className="mb-20">MoveInfo</h2>
        <hr />
        <hr />
      </div>
      <section className="move-block">
        <MoveInfo />
      </section>
      <ExternalLink
      title='abc'
      >
        <h2>click</h2>
      </ExternalLink>

      <StickyButton />
    </HcpLayout>
  );
};

export default Allcomponents;

export const Head = () => <Seo title="Components" />;
