import React from "react";
import "./cardDownload.scss";
import { Col, Row } from "react-grid-system";
import Button from "../../button";
import downloadIcon from "../../../images/icons/download-icon.svg";

function CardDownload(props) {
  return (
    <div className="card-download bg-cover border-32">
      <Row className="row">
        <Col lg={8}>
          <div className="card-download__content">
            <h2>{props.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: props.desc }}></div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="btn-wrap">
            <a href={props.downloadBtnUrl} target="_blank" rel="noreferrer">
              <Button
                className="btn-primary btn-xs border-28"
                btnTitle="Download Now"
                btnIcon={downloadIcon}
              />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CardDownload;
